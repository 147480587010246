<template>
  <octo-table
    show-filters
    :show-filters-collapse="false"
    :action="endpoints.DATATABLES.callCenterLeads"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="resetFilters"
  >
    <template v-slot:name="data">
      <div class="text-uppercase">
        {{ data.row.surname }} {{ data.row.name }}
      </div>
    </template>

    <template v-slot:callCenterLeadStatus="data">
      <badge :type="data.row.status | leadStatusColor" class="text-uppercase">
        {{ $t('callcenter.status.' + data.row.status) }}
      </badge>
    </template>

    <template v-slot:phone="data">
      <div class="text-truncate small">
        {{ data.row.phones }}
      </div>
    </template>

    <template v-slot:deadline="data">
      <div class="small">{{ data.row.deadline | datetime }}</div>
    </template>

    <template v-slot:email="data">
      <div class="text-truncate small">
        {{ data.row.emails }}
      </div>
    </template>

    <template v-slot:date="data">
      <div class="small">{{ data.row.date_in | date }}</div>
    </template>

    <template v-slot:operator="data">
      <div class="text-truncate text-uppercase">
        {{ data.row.operator }}
      </div>
    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import {Option, Select} from "element-ui";
import {mapGetters} from "vuex";
import Campaign from "@/models/campaign";

export default {
  name: "CampaignLeadDatatable",
  components: {Badge, OctoIcon, OctoTable, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      endpoints: endpoints,
      filters: {
        campaignIds : [this.campaign.id]
      },
      fields: [
        {prop: 'date', label: 'date', align: 'center', slot: true, width: 100,fixed:true},
        {prop: 'name', label: 'name', slot: true, align: 'left',width: 250,fixed:true},
        {prop: 'callCenterLeadStatus', label: 'call_center_lead_status', width: 180, slot: true, align: 'center'},
        {prop: 'deadline', label: 'deadline', align: 'center', slot: true, width: 150,},
        {prop: 'operator', label: 'operator', slot: true, width: 180, align: 'center'},
        {prop: 'phone', label: 'phone', align: 'center', slot: true, width: 200,},
        {prop: 'email', label: 'email', align: 'center', slot: true, width: 250}
      ],
    }
  },
  props: {
    campaign: {
      type: Object,
      default: () => this.$_.cloneDeep(Campaign)
    }
  },
  methods: {
    resetFilters: function () {
      this.filters = {
        campaignIds : [this.campaign.id]
      };
    },
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
    this.filters.campaignIds = [this.campaign.id]
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
    }),

    name: function () {
      return "campaignLeadDatatable-" + this.campaign.id
    },
  }
}
</script>

<style scoped>

</style>
