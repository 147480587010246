<template>

  <ul class="list-group list-group-flush">
    <list-group-item-component :label="$t('fields.deadline')" :label-width="labelWidth">
      <template slot="value">
        <base-input
          type="text"
          class="mb-0"
          v-model="rulesLocal.deadline"/>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="`${$t('fields.status')} lead`" :label-width="labelWidth">
      <template slot="value">
        <list-selector-component :list="ruleLeadStatuses" ref="leadStatusesSelector">
          <template v-slot:default="slotProps">
            <div class="text-uppercase">{{$t('callcenter.status.' + slotProps.item.value)}}</div>
          </template>
        </list-selector-component>
      </template>
    </list-group-item-component>
    <list-group-item-component :label="$t('fields.course')" :label-width="labelWidth">
      <template slot="value">
        <list-selector-component :list="ruleCourses" ref="ruleCoursesSelector">
          <template v-slot:default="slotProps">
            <div class="text-uppercase small">{{slotProps.item.value}}</div>
          </template>
        </list-selector-component>
      </template>
    </list-group-item-component>
  </ul>

</template>

<script>
import {Select, Option} from 'element-ui';
import {CampaignRules} from "@/models/campaign";
import Slider from "@/components/Slider";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import CallCenterLeadStatuses from "@/pages/CallCenter/resources/callCenterLeadStatuses";
import ListSelectorComponent from "@/components/ListSelectorComponent.vue";
import {mapGetters} from "vuex";

export default {
  name: "CampaignRulesForm",
  components: {
    ListSelectorComponent,
    BaseTextArea,
    ListGroupItemComponent,
    Slider,
    [Option.name]: Option,
    [Select.name]: Select
  },
  data() {
    return {
      labelWidth: 200,
      rulesLocal: _.cloneDeep(CampaignRules),
      ruleLeadStatuses: [],
      ruleCourses: [],
    }
  },
  props: {
    rules: {
      type: Object,
      default: () => _.cloneDeep(CampaignRules)
    }
  },
  computed: {
    ...mapGetters({
      courses: 'common/courses',
    }),
  },
  mounted() {
    this.rulesLocal = this.$_.cloneDeep(this.rules);
    this.ruleLeadStatuses = this.$_.map(CallCenterLeadStatuses, (status) => {
      return {
        value : status.value,
        id: status.value,
        selected: this.rulesLocal.statuses?.includes(status.value),
      }
    });

    this.ruleCourses = this.$_.map(this.courses, (course) => {
      return {
        value : `[ ${course.code} ] ${course.title}`,
        id: course.id,
        selected: this.rulesLocal.courses?.includes(course.id),
      }
    });
  },
  methods: {
    getCampaignRules: function async () {
      const data = {
        courses: this.$_.map(this.$refs.ruleCoursesSelector.getSelectedItems(), item => item.id),
        statuses: this.$_.map(this.$refs.leadStatusesSelector.getSelectedItems(), item => item.id),
        deadline: this.rulesLocal.deadline,
      };

      return new Promise((resolve) => {
        resolve(data);
      });
    }
  }
}
</script>

<style scoped>

</style>
