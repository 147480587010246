<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card card-plain">
          <tabs centered square>
            <tab-pane>
              <template slot="label">
                <div class="text-uppercase m-3">{{ $t('page.campaign_data') }}</div>
              </template>
              <div class="row">
                <div class="col-12">
                  <octo-header-button
                    :buttons="campaignButtons"
                    :title="campaign.name"
                    @onSaveCampaign="saveCampaign"
                    @onActivateCampaign="activateCampaign"
                    @onDeactivateCampaign="deactivateCampaign"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-12">
                  <card body-classes="standard-card-body" class="shadow">
                    <div slot="header"><h4 class="card-title text-capitalize">{{ $t('page.campaign_data') }}</h4></div>
                    <campaign-form :key="`form-${campaignKey}`" :campaign="campaign" ref="campaignForm"/>
                  </card>
                </div>
                <div class="col-12 col-md-12">
                  <card body-classes="standard-card-body" class="shadow">
                    <div slot="header"><h4 class="card-title text-capitalize">{{ $t('lead.campaign_rules') }}</h4></div>
                    <campaign-rules-form  :key="`form-${campaignKey}`" :rules="campaign.rules" ref="campaignRulesForm"/>
                  </card>
                </div>
              </div>
            </tab-pane>
            <tab-pane v-if="campaign.id">
              <template slot="label">
                <div class="text-uppercase m-3">{{ $t('page.campaign_visibility') }}</div>
              </template>
              <div class="row">
                <div class="col-12">
                  <octo-header-button
                    :buttons="visibilityButtons"
                    :title="campaign.name"
                    @onUpdateVisibility="updateVisibility"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-md-6">
                  <card body-classes="standard-card-body" class="shadow-sm">
                    <div slot="header">
                      <h4 class="card-title text-capitalize">{{ $t('fields.call_center_operators') }}</h4>
                    </div>
                    <list-selector-component :paginate="false" :number-items-per-page="12" :list="operatorUsers" ref="operatorSelector">
                      <template v-slot:default="slotProps">
                        <div class="text-uppercase small">
                          {{ users[slotProps.item.id] | optional('lastname') }}<br/>
                          {{ users[slotProps.item.id] | optional('firstname') }}
                        </div>
                      </template>
                    </list-selector-component>
                  </card>
                </div>

                <div class="col-12 col-md-6">
                  <card body-classes="standard-card-body" class="shadow-sm">
                    <div slot="header">
                      <h4 class="card-title text-capitalize">{{ $t('fields.sales') }}</h4>
                    </div>

                    <list-selector-component :paginate="false" :number-items-per-page="12" :list="salesUsers" ref="salesSelector">
                      <template v-slot:default="slotProps">
                        <div class="text-uppercase small">
                          {{ users[slotProps.item.id] | optional('lastname') }}<br/>
                          {{ users[slotProps.item.id] | optional('firstname') }}
                        </div>
                      </template>
                    </list-selector-component>
                  </card>
                </div>
              </div>
            </tab-pane>
            <tab-pane  v-if="campaign.id">
              <template slot="label">
                <div class="text-uppercase m-3">{{ $t('page.leads') }}</div>
              </template>
              <div class="row">
                <div class="col-12">
                  <octo-header-button :title="campaign.name"/>
                </div>
              </div>
              <card body-classes="standard-card-body" class="shadow">
                <campaign-lead-datatable :campaign="campaign" :key="`datatable-${campaignKey}`"/>
              </card>
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import Campaign, {CampaignRules} from "@/models/campaign";
import CampaignForm from "@/pages/Leads/forms/CampaignForm";
import {TabPane, Tabs} from '@/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import CampaignFilterList from "./components/CampaignFilterList";
import {mapGetters, mapMutations} from "vuex";
import Card from "@/components/Cards/Card";
import ListSelectorComponent from "@/components/ListSelectorComponent";
import CampaignLeadDatatable from "./datatables/CampaignLeadDatatable";
import {MessageBox} from "element-ui";
import CampaignRulesForm from "@/pages/Leads/forms/CampaignRulesForm.vue";

export default {
  name: "ShowPage",
  components: {
    CampaignRulesForm,
    CampaignLeadDatatable,
    ListSelectorComponent,
    CampaignFilterList,
    Tabs,
    TabPane,
    CampaignForm,
    Card,
    OctoIcon,
    OctoHeaderButton,
  },
  data() {
    return {
      endpoints: endpoints,
      campaign: this.$_.cloneDeep(Campaign),
      campaignKey: 1,
      operatorUsers: [],
      salesUsers: [],
      visibilityButtons: [
        {
          onClick: 'onUpdateVisibility',
          label: 'save',
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      operators: 'common/operators',
      sales: 'common/sales',
    }),

    campaignButtons() {
      const buttons = [{
        label: 'save',
        onClick: 'onSaveCampaign'
      }];

      if (this.campaign.name && this.campaign.name !== 'default') {
        buttons.unshift({
          label: 'activate',
          onClick: 'onActivateCampaign',
        });
        buttons.unshift({
          label: 'deactivate',
          onClick: 'onDeactivateCampaign',
        });
      }

      return buttons;
    }
  },
  mounted() {

    const campaignId = this.$route.params.id;

    console.log('sto passando dal mounted...');

    if(isNaN(campaignId)) {
      this.campaign = this.$_.cloneDeep(Campaign);
      this.operatorUsers = [];
      this.salesUsers = [];
      this.campaignKey++;
    } else {
      this.$fullLoading.show();
      this.$api.get(endpoints.CAMPAIGN_SHOW.replace('{id}', campaignId))
        .then((resp) => {
          this.campaign = resp.data.campaign;
          this.operatorUsers = resp.data.operatorUsers;
          this.salesUsers = resp.data.salesUsers;
          if(!this.campaign.rules) {
            this.campaign.rules = _.cloneDeep(CampaignRules)
          }

          this.campaignKey++;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
          this.$router.push({name: 'leads.campaigns'})
        })
        .finally(() => {
          this.$fullLoading.hide();
        });
    }


  },
  methods: {
    ...mapMutations({
      updateCampaigns: 'common/updateCampaigns'
    }),

    saveCampaign: async function () {
      try {
        const campaignData = await this.$refs.campaignForm.getCampaignData();
        campaignData.rules = await this.$refs.campaignRulesForm.getCampaignRules();

        this.$fullLoading.show();

        const resp = this.campaign.id
          ? await this.$api.put(endpoints.CAMPAIGN_UPDATE.replace('{id}', this.campaign.id), campaignData)
          : await this.$api.post(endpoints.CAMPAIGN_STORE, campaignData);

        if(this.campaign.id) {
          await this.updateCampaigns(resp?.data?.data);
          this.campaign = resp?.data?.data;
          this.campaignKey++;
        } else {
          this.$router.push({name: 'leads.campaigns.show', params: {id : resp?.data?.data?.id }})
        }


        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        console.log(e);
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    updateVisibility: async function () {
      try {
        const selectedUsers = await this.$_.concat(
          this.$_.map(this.$refs.operatorSelector.getSelectedItems(), item => item.id),
          this.$_.map(this.$refs.salesSelector.getSelectedItems(), item => item.id)
        );

        this.$fullLoading.show();

        const resp = await this.$api.put(
          endpoints.CAMPAIGN_UPDATE_VISIBILITY.replace('{id}', this.campaign.id),
          {selectedUsers: selectedUsers}
        );

        this.operatorUsers = resp?.data?.operatorUsers;
        this.salesUsers = resp?.data?.salesUsers;

        this.campaignKey++;

        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        console.log(e);
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    activateCampaign() {
      this.$api.post(endpoints.CAMPAIGN_ACTIVATE.replace('{id}', this.campaign.id))
        .then(() => {
          MessageBox({
            title: this.$t('common.good_job'),
            message: this.$t('lead.wizard_campaign.activate_campaign'),
            confirmButtonText: this.$t('common.ok'),
            type: 'success'
          });
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.job_not_executed')});
        });
    },

    deactivateCampaign() {
      this.$api.post(endpoints.CAMPAIGN_DEACTIVATE.replace('{id}', this.campaign.id))
        .then(() => {
          MessageBox({
            title: this.$t('common.good_job'),
            message: this.$t('lead.wizard_campaign.deactivate_campaign'),
            confirmButtonText: this.$t('common.ok'),
            type: 'success'
          });
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.job_not_executed')});
        });
    }
  },
}
</script>

<style scoped>

</style>
